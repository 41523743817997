<template>
  <div class="device-item-container">
    <div class="left-box">
      <div class="top">
        <div class="device-name"> {{ deviceitem.deviceName }}</div>
        <div class="ul-singn">
          <div v-for="(imgSrc, index) in SIGNAL_LIST" :key="index" class="li-singn" :style="{
                  width: '4px',
                  height: 4 * index + 'px',
                  'background-color': calcSIGNAL(JSON.parse(`${deviceitem.deviceSignal}`), index),
                  'border-right': '1px solid #aaa',
                }"></div>
          <div class="no-singn" v-if="JSON.parse(`${deviceitem.deviceSignal}`) === 0">|
          </div>
        </div>
      </div>
      <div class="bottom">
        <div v-if="deviceitem.deviceStatus === '1' ">
          <div class="device-rule" v-if="curtNum>0">
            当前可点亮:{{ curtNum }}盏
          </div>
          <div class="device-rule" v-else>没有可立即点亮的灯盏</div>
          <!--          <div class="device-rule" v-if="deviceitem.canItTime && deviceitem.deviceType === 3">-->
          <!--            满堂红预约点亮时间{{ deviceitem.canItTime }}分钟-->
          <!--          </div>-->
          <div class="device-rule" v-show="deviceitem.deviceType === '3' || deviceitem.deviceType === '4'">
            <span class="device-rule">
              满堂红可立即点亮
            </span>
          </div>
        </div>
        <div class="device-rule" v-else>当前心灯已离线</div>
        <div class="terminal">终端号：{{ deviceitem.deviceImei }}</div>
      </div>
    </div>
    <div class="right-box">
      <div class="preview-btn" :class="deviceitem.deviceStatus === '0' ? 'die-btn' : ''"
           @click.stop="gotoLight(deviceitem.deviceStatus, deviceitem.deviceImei,deviceitem.deviceType)">
        点灯
      </div>
      <div v-if="deviceitem.deviceType !== 1" class="preview-btn"
           :class="deviceitem.deviceStatus === '0' ? 'die-btn' : ''"
           @click.stop="previewBtn(deviceitem.deviceImei)">
        预览
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { mapGetters } from 'vuex'

export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    deviceitem: {
      type: Object,
      require: true
    }
  },
  // 这里用于存放数据
  data () {
    return {

      SIGNAL_LIST: [1, 2, 3, 4, 5, 6],
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapGetters('device',['getDeviceItem']),
    curtNum () {
      let zeroCount = 0
      zeroCount = Object.values(this.deviceitem.deviceStatusMap).filter(value => value === 0).length
      return zeroCount
    }
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    // 心灯信号图标显示
    calcSIGNAL (signal, index) {
      if (signal >= 26 && index <= 5) {
        return '#333'
        // `backgroundColor": (backgroundColor = "#333")`
        // index
      } else if (signal >= 21 && index <= 4) {
        return '#333'
        //  index
      } else if (signal >= 17 && index <= 3) {
        return '#333'
        // index
      } else if (signal >= 13 && index <= 2) {
        return '#333'
        // index
      } else if (signal >= 6 && index <= 1) {
        return '#333'
      } else {
        return '#ccc'
        // index
      }
    },
    gotoLight (deviceStatus, deviceImei, deviceType) {
      const device = this.getDeviceItem(deviceImei)
      this.$store.commit('device/setDevice',device)
      //进入点灯页
      this.$router.push({
        path: '/devicelight',
        query: {
          IMEIID: deviceImei,
          deviceType: deviceType
        }
      })
    },
    /* 可用灯数 */

    previewBtn (deviceImei) {
      this.$emit('previewDevice', deviceImei)
    },

  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style lang="less" scoped>
.device-item-container {
  margin: 5px 10px;
  display: flex;

  padding: 15px;
  height: 116px;
  box-sizing: border-box;
  background: rgba(242, 104, 57, .04);
  border-radius: 5px;

  .left-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    height: 100vh;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 55vw;
      font-size: 13px;
      margin-bottom: 5px;
      font-weight: bold;

      .device-name {
        font-size: 18px;
        font-weight: bold;
      }

      .ul-singn {
        position: relative;
        display: flex;
        width: 40px;
        height: 30px;
        justify-content: center;
        align-items: flex-end;
        line-height: 30px;
        padding-bottom: 7px;
        box-sizing: border-box;
      }

      .no-singn {
        position: absolute;
        left: 45%;
        top: 0;
        font-size: 30px;
        transform: rotate(-45deg);
        color: #aaa;
      }

    }

    .bottom {

      display: flex;
      flex-direction: column;
      width: 55vw;

      .device-rule {
        font-size: 12px;
        color: #666;
      }

      .terminal {
        font-size: 10px;
        color: #aaa;
      }
    }
  }

  .right-box {
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .preview-btn {
      width: 100px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      color: #fff;
      font-size: 13px;
      background-color: #A92B2A;
      border-radius: 5px;
    }

    .die-btn {
      opacity: 0.4; /* 设置透明度为 0.6，使其看起来禁用 */
      pointer-events: none; /* 禁用鼠标交互 */
      cursor: not-allowed; /* 设置光标为禁用样式 */
    }
  }
}
</style>
